export const EyeOff: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.76406 3.29519C9.46644 3.10724 10.2123 3 11 3C17.3636 3 21 10 21 10C21 10 20.171 11.5958 18.612 13.2635M3.34912 6.77822C1.8152 8.43073 1 10 1 10C1 10 4.63636 17 11 17C11.8021 17 12.5608 16.8888 13.2744 16.6944M10.5 12.9585C9.41582 12.7766 8.52883 12.0132 8.17071 11M11.5 7.04148C12.7563 7.25224 13.7478 8.24375 13.9585 9.5M2 1L20 19"
      stroke="#6AA543"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
