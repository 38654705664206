import Alert from "@components/ui/alert";
import Button from "@components/ui/button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLoginMutation } from "@data/user/use-login.mutation";
import { useTranslation } from "next-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { allowedRoles, hasAccess, setAuthCredentials, mathRouterWithPath } from "@utils/auth-utils";
import Checkbox from "@components/ui/checkbox/checkbox";
import { MailIcon } from "@components/icons/new/mail-icon";
import { useLocalStorage } from "@lib/use-local-storage";
import { encryption, decryption } from "@lib/encryption-and-decryption";
import IconInsideInput from "@components/ui/icon-inside-input";
import IconInsidePasswordInput from "@components/ui/icon-inside-password-input";
import { CUSTOMER } from "../../utils/constants";

type FormValues = {
  email: string;
  password: string;
  remember_password: boolean;
};
const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("form:error-email-format")
    .required("form:error-email-required"),
  password: yup.string().trim().required("form:error-password-required"),
});

const LoginForm = () => {
  const { mutate: login, isLoading: loading } = useLoginMutation();
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation();
  const [inforUserRemember, setInforUserRemember] = useLocalStorage<{
    [key: string]: any;
  }>("inforUserRemember", {
    rememberPassword: false,
    email: "",
    password: "",
  });

  const [_, setCurrentEmail] = useLocalStorage<any>("currentEmail", '');

  const defaultValues = {
    email: decryptionRemember("email"),
    password: decryptionRemember("password"),
    remember_password: inforUserRemember?.rememberPassword,
  };

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(loginFormSchema),
  });

  function decryptionRemember(key: string): string | undefined {
    const value = inforUserRemember?.[key];
    if (value) return decryption(value);
  }

  function storeRememberPassword({
    email,
    password,
    remember_password,
  }: FormValues) {
    if (remember_password) {
      setInforUserRemember({
        email: encryption(email),
        password: encryption(password),
        rememberPassword: remember_password,
      });
    } else {
      setInforUserRemember({
        rememberPassword: false,
        email: "",
        password: "",
      });
    }
  }

  function onSubmit({ email, password, remember_password }: FormValues) {
    login(
      {
        variables: {
          email,
          password,
        },
      },
      {
        onSuccess: async ({ data }) => {
          if (data?.token) {
            let permissions = data?.permissions;
            setCurrentEmail(email);

            if (hasAccess(allowedRoles, permissions) && permissions != [CUSTOMER]) {
              permissions = permissions.filter((item: string) => item != CUSTOMER)
              await setAuthCredentials(data?.token, permissions);
              await storeRememberPassword({ email, password, remember_password });
              window.location.href = mathRouterWithPath[permissions[0]];
              return;
            }
            setErrorMsg("form:error-enough-permission");
          } else {
            setErrorMsg("form:error-credential-wrong");
          }
        },
        onError: () => { },
      }
    );
  }

  return (
    <>
      {errorMsg ? (
        <Alert
          message={t(errorMsg)}
          variant="error"
          closeable={true}
          className="mb-5 not-italic leading-5 text-sm font-nova"
          onClose={() => setErrorMsg("")}
        />
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <IconInsideInput
          label={t("form:input-label-email")}
          {...register("email")}
          type="email"
          variant="outline"
          className="mb-6 w-full"
          error={t(errors?.email?.message!)}
          placeholder={t("form:text-placeholder-email")}
          icon={<MailIcon />}
        />
        <IconInsidePasswordInput
          label={t("form:input-label-password")}
          {...register("password")}
          error={t(errors?.password?.message!)}
          variant="outline"
          className="mb-18px"
          placeholder={t("form:text-placeholder-password")}
        />
        <Checkbox
          {...register("remember_password")}
          label={t("form:text-remember-password")}
          className="mb-42px"
        />
        <Button
          className="w-full bg-default rounded"
          size="big"
          loading={loading}
          disabled={loading}
        >
          {t("form:button-label-login")}
        </Button>
      </form>
    </>
  );
};

export default LoginForm;
