import { Eye } from "@components/icons/eye-icon";
import { EyeOff } from "@components/icons/eye-off-icon";
import cn from "classnames";
import React, { InputHTMLAttributes, useState } from "react";
import { LockIcon } from '@components/icons/new/lock-icon';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  forgotPassHelpText?: string;
  name: string;
  label: string;
  forgotPageLink?: string;
  shadow?: boolean;
  variant?: "normal" | "solid" | "outline";
  error: string | undefined;
}
const classes = {
  root: "ps-17 pe-4 h-13.5 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0",
  normal:
    "bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent-800",
  solid:
    "bg-gray-100 border border-border-100 focus:bg-light focus:border-accent-800",
  outline: "border border-border-base focus:border-accent-800",
  shadow: "focus:shadow",
};
const IconInsidePasswordInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      inputClassName,
      forgotPassHelpText,
      name,
      label,
      error,
      children,
      variant = "normal",
      shadow = false,
      type = "text",
      forgotPageLink = "",
      ...rest
    },
    ref
  ) => {
    const [show, setShow] = useState(false);

    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === "normal",
        [classes.solid]: variant === "solid",
        [classes.outline]: variant === "outline",
      },
      shadow == true && classes.shadow,
      inputClassName
    );

    return (
      <div>
        <label
          htmlFor={name}
          className="block mb-2 not-italic leading-5 text-sm"
        >
          {label}
        </label>
        <div className={cn("w-full block items-center relative", className)} >
          <div className="outline-none absolute start-1 focus:outline-none active:outline-none p-2 text-body border-r w-50px pl-4 mt-2.5">
            <LockIcon />
          </div>
          <div className="w-full">
            <input
              id={name}
              name={name}
              type={show ? "text" : "password"}
              ref={ref}
              className={rootClassName}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              {...rest}
            />
            <label
              htmlFor={name}
              className="absolute end-4 top-4 -m-px text-body"
              onClick={() => setShow((prev) => !prev)}
            >
              {show ? (
                <EyeOff className="w-5 h-5" />
              ) : (
                <Eye className="w-5 h-5" />
              )}
            </label>
          </div>
          {error && (
            <p className="my-2 text-start text-red-500 not-italic leading-5 text-sm font-nova">{error}</p>
          )}
        </div>
      </div>
    );
  }
);

export default IconInsidePasswordInput;
