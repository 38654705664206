import React, { InputHTMLAttributes } from "react";
import cn from "classnames";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  label?: string;
  note?: string;
  name: string;
  error?: string;
  type?: string;
  shadow?: boolean;
  variant?: "normal" | "solid" | "outline";
  icon: any;
}

const classes = {
  root: "ps-17 pe-4 h-13.5 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0",
  normal:
    "bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent-800",
  solid:
    "bg-gray-100 border border-border-100 focus:bg-light focus:border-accent-800",
  outline: "border border-border-base focus:border-accent-800",
  shadow: "focus:shadow",
};

const IconInsideInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      note,
      name,
      error,
      children,
      icon,
      variant = "normal",
      shadow = false,
      type = "text",
      inputClassName,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === "normal",
        [classes.solid]: variant === "solid",
        [classes.outline]: variant === "outline",
      },
      {
        [classes.shadow]: shadow,
      },
      inputClassName
    );

    return (
      <div>
        <label
          htmlFor={name}
          className="block mb-2 not-italic font-normal text-sm"
        >
          {label}
        </label>
        <div className={cn("w-full block items-center relative", className)} >
          <div className="outline-none absolute start-1 focus:outline-none active:outline-none p-2 text-body border-r w-50px pl-4 mt-2.5">
            { icon }
          </div>
          <input
            id={name}
            name={name}
            type={type}
            ref={ref}
            className={rootClassName}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            aria-invalid={error ? "true" : "false"}
            {...rest}
          />
          {note && <p className="mt-2 text-body not-italic leading-5 text-sm font-nova">{note}</p>}
          {error && (
            <p className="my-2 text-start text-red-500 not-italic leading-5 text-sm font-nova">{error}</p>
          )}
        </div>
      </div>
    );
  }
);

export default IconInsideInput;
